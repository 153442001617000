<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="8">
								<h4>Tambah Tujuan Penggunaan Benih</h4>
							</CCol>
						</CRow>
					</CCardHeader>
					<CForm v-on:submit.prevent="storeProcess" class="fwdwd">
						<CCardBody>
							<div class="form-group">
								<label for=""><b>Kategori Module <span class="text-danger">*</span></b></label>
								<v-select :options="list_kategori" v-model="kategori_selected" placeholder="Pilih Kategori Module"></v-select>
							</div>
							<div class="form-group">
								<label for=""><b>Tipe Permohonan</b></label>
                                <v-select :options="filter_tipe" :reduce="label => label.value" label="label" v-model="is_pemasukan" :value="$store.myValue" placeholder="Pilih Tipe Permohonan"></v-select>
							</div>
							<div class="form-group">
								<label for=""><b>Nama Tujuan Penggunaan Benih <span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" placeholder="Nama Tujuan Penggunaan Benih" v-model="tpb_nama">
							</div>
							<div class="form-group">
								<label for=""><b>Tipe Pemohon <span class="text-danger">*</span></b></label>
								<v-select :options="list_tipe_pemohon" label="tp_nama" v-model="tipe_pemohon"></v-select>
							</div>
						</CCardBody>
						<CCardFooter>
							<div class="text-right">
								<button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
							</div>
						</CCardFooter>
					</CForm>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
const null_array = [];
	export default {
		name: "AddTujuanBenih",
		data() {
			return {
				tpb_nama: '',
				list_tipe_pemohon: null_array,
				tipe_pemohon: '',
				list_kategori: [],
				kategori_selected: '',
				filter_tipe: [
                    {
                        value: "1",
                        label: "Pemasukkan Benih Tanaman",
                    },
                    {
                        value: "0",
                        label: "Pengeluaran Benih Tanaman",
                    },
                ],
				is_pemasukan: '',
			};
		},
		methods: {
			storeProcess: function () {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
					allowOutsideClick: false,
				});
				var FormData = require('form-data');
				var data = new FormData();
				data.append('tpb_nama', this.tpb_nama);
				data.append('tipe_pemohon_id', this.tipe_pemohon?.id);
				data.append('kategori_module', this.kategori_selected);
				data.append('is_pemasukan', this.is_pemasukan);
				
				
				var config = {
				  method: 'post',
				  url: this.apiLink+'api/crud/tujuan_penggunaan_benih',
				  headers: { 
					'Authorization': 'Bearer '+this.access_token, 
				  },
				  data : data
				};
				axios(config)
				.then(function (response) {
					var res_data = response.data;
					// console.log(res_data);
					if (res_data.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_data.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Anda akan diarahkan ke halaman master dokumen persyaratan segera",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								self.$router.push({name: 'AllTujuanBenih'});
							}
						});
					}
				});
			},
		},
		created() {
			// Get Master Negara
			axios.get(this.apiLink + "api/master/tipe_pemohon", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_tipe_pemohon = response.data;
				var res_tipe_pemohon_data = res_tipe_pemohon.data;
				if (res_tipe_pemohon.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_tipe_pemohon.data.message
					});
				}
				else{
					this.list_tipe_pemohon = res_tipe_pemohon_data.master;
				}
			});
			axios
                .get(this.apiLink + "api/master/category", {
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_join_data_master = response.data;
                    // console.log();
                    if (res_join_data_master.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_join_data_master.data.message,
                        });
                    } else {
                        this.list_kategori = res_join_data_master.data.master;
                    }
                });
		}
	};
</script>